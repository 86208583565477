import React, { useCallback, useMemo, useState } from 'react'

import { LayoutProps, SpaceProps } from 'styled-system'

import { API } from 'API'
import { EmployeePickerApiReturn } from 'API/Employee/useLazyEmployeesByCursor/types'

import { Flex, Span } from 'components/ui/__v2__/Grid'
import { Select, Switch } from 'components/ui/__v3__'

import { createpDebounce } from 'helpers/debounce'

import { i18n } from 'i18n'

import {
  employeeMemberToOption,
  exposeEmployeeOption,
  filterEmployeesById,
  matchNameOrCode,
} from './helpers'
import { HelperText } from './styles'
import { EmployeePickerArgs, EmployeePickerInternalOption } from './types'

export function EmployeePicker({
  areas,
  value,
  onSelect,
  isMulti,
  withArchivedToggle,
  labelContent,
  withArchived = false,
  excludeIds,
  onToggleArchived,
  ...rest
}: EmployeePickerArgs & LayoutProps & SpaceProps) {
  // Note: used to toggle display of archived employees, originally implemented for timecard screens
  const [includeArchived, setIncludeArchived] = useState(withArchived)

  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [employees, setEmployees] = useState<EmployeePickerApiReturn[]>([])

  const { loadEmployees } = API.Employee.byCursorLazy({
    filters: { areas },
    parameters: { includeArchived },
  })

  // NOTE: hack to render correct menu height for first render in scrollable view
  const handleMenuOpen = useCallback(async () => {
    setLoading(true)

    const result = await loadEmployees({})
    setMenuOpen(false)

    setEmployees(result)

    setTimeout(() => {
      setLoading(false)
      setMenuOpen(true)
    }, 100)
  }, [loadEmployees])

  const handleChange = isMulti
    ? (option: EmployeePickerInternalOption[] | null) =>
        onSelect(option?.map(exposeEmployeeOption) ?? null)
    : (option: EmployeePickerInternalOption | null) =>
        onSelect(option ? exposeEmployeeOption(option) : option)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleInput = useCallback(
    createpDebounce(async (inputValue: string, callback: Function) => {
      const searchedEmployees = await loadEmployees({
        nameOrCode: inputValue,
      })

      const filteredEmployees = excludeIds
        ? filterEmployeesById(searchedEmployees ?? [], excludeIds)
        : searchedEmployees ?? []

      callback(filteredEmployees.map(employeeMemberToOption))
    }),
    [loadEmployees, excludeIds],
  )

  const handleIncludeArchived = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isArchived = event.target.checked
    setIncludeArchived(isArchived)

    // NOTE: need it if component unmounted and mounted to save toggle animation
    if (onToggleArchived) {
      setTimeout(() => {
        onToggleArchived(isArchived)
      }, 100)
    }
  }

  // NOTE: to refresh the default values
  const handleInputChange = (
    inputValue: string,
    action: { action: string },
  ) => {
    if (action.action !== 'input-change' || inputValue) return

    loadEmployees({})
  }

  const defaultOptions = useMemo(() => {
    const filteredEmployees = excludeIds
      ? filterEmployeesById(employees, excludeIds)
      : employees

    return filteredEmployees.map(employeeMemberToOption)
  }, [employees, excludeIds])

  return (
    <Flex flexDirection="column" width="100%">
      <Select
        // @ts-ignore
        async
        closeMenuOnSelect={!isMulti}
        defaultOptions={defaultOptions}
        filterOption={matchNameOrCode}
        isLoading={isLoading}
        isMulti={isMulti}
        isSearchable={!isLoading}
        labelContent={labelContent}
        loadOptions={handleInput}
        menuIsOpen={isMenuOpen}
        placeholder={i18n('employeePicker.nameOrCode')}
        value={value ?? null}
        withPortal
        onChange={handleChange}
        onInputChange={handleInputChange}
        onMenuClose={() => setMenuOpen(false)}
        onMenuOpen={handleMenuOpen}
        {...rest}
      />
      {withArchivedToggle && (
        <HelperText>
          <Switch
            checked={includeArchived}
            height={14}
            width={28}
            onChange={handleIncludeArchived}
          />
          <Span>{i18n('employeePicker.includeArchived')}</Span>
        </HelperText>
      )}
    </Flex>
  )
}
